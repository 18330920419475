import { clsx } from "clsx";
import { useContext, useEffect, useState } from "react";
import { Alert, FormGroup, Select } from "@moovfinancial/cargo";
import useAccountLocking from "hooks/useAccountLocking";
import useAccountRequirements from "hooks/useAccountRequirements";
import { AccountInput } from "../components/AccountInput";
import { PhoneField } from "../components/PhoneField";
import { PrefillAlert } from "../components/PrefillAlert";
import { Step } from "components/dialog/StepModal";
import { Label } from "components/form/Form";
import Toaster from "components/toaster/Toaster";
import { AccountSetupContext } from "../../AccountSetupContext";
import useAccountModalCalls from "../../useAccountModalCalls";
import styles from "./IndividualBasicDetails.module.scss";

export const IndividualBasicDetails = () => {
  const {
    account,
    dispatch,
    errorMessages,
    resetInput,
    invalidInputs,
    setInvalidInputs,
    capabilityRequirements
  } = useContext(AccountSetupContext);
  const { createAccount, updateAccount, loading } = useAccountModalCalls();

  const { accountLocks } = useAccountLocking();
  const { accountRequirements } = useAccountRequirements(capabilityRequirements);
  const [alertPhoneOrEmail, setAlertPhoneOrEmail] = useState(false);

  useEffect(() => {
    if (
      alertPhoneOrEmail &&
      (account.profile?.individual?.phone?.number || account.profile?.individual?.email)
    ) {
      setAlertPhoneOrEmail(false);
    }
  }, [account.profile?.individual?.phone?.number, account.profile?.individual?.email]);

  const handleSubmit = () => {
    let canContinue = true;
    if (!account.profile?.individual?.name.firstName) {
      setInvalidInputs((prev) => [...prev, "firstName"]);
      canContinue = false;
    }
    if (!account.profile?.individual?.name.lastName) {
      setInvalidInputs((prev) => [...prev, "lastName"]);
      canContinue = false;
    }
    if (
      (!account.profile?.individual?.phone?.number || invalidInputs.includes("phone")) &&
      (!account.profile?.individual?.email || invalidInputs.includes("email"))
    ) {
      setAlertPhoneOrEmail(true);
      canContinue = false;
    }

    if (canContinue) {
      account.accountID ? updateAccount() : createAccount();
    }
  };

  return (
    <Step
      className={styles.individualBasicDetails}
      onSubmit={handleSubmit}
      loading={loading}
      noValidate
    >
      <PrefillAlert />
      {errorMessages && <Toaster toastInput={errorMessages} duration={4000} />}
      <div className={styles.grid}>
        <AccountInput
          label="First name"
          name="firstName"
          value={account.profile?.individual?.name.firstName}
          showDefaultWarningMessage={!!accountRequirements?.profile?.individual?.name?.firstName}
          isLocked={!!accountLocks?.profile?.individual?.name?.firstName}
          dispatchType="individualName"
          required
        />
        <AccountInput
          label="Middle name"
          name="middleName"
          value={account.profile?.individual?.name.middleName}
          isLocked={!!accountLocks?.profile?.individual?.name?.middleName}
          dispatchType="individualName"
        />
        <AccountInput
          label="Last name"
          name="lastName"
          value={account.profile?.individual?.name.lastName}
          showDefaultWarningMessage={!!accountRequirements?.profile?.individual?.name?.lastName}
          isLocked={!!accountLocks?.profile?.individual?.name?.lastName}
          dispatchType="individualName"
          required
        />
        <FormGroup as="label">
          <Label>Suffix</Label>
          <Select
            autoComplete="off"
            disabled={!!accountLocks?.profile?.individual?.name?.suffix}
            isLocked={!!accountLocks?.profile?.individual?.name?.suffix}
            value={account.profile?.individual?.name.suffix || ""}
            name="suffix"
            onChange={(e) => {
              resetInput("suffix");
              dispatch({
                type: "individualName",
                value: { suffix: e.target.value }
              });
            }}
          >
            <option value="">None</option>
            <option value="Sr.">Sr.</option>
            <option value="Jr.">Jr.</option>
            <option value="II">II</option>
            <option value="III">III</option>
            <option value="IV">IV</option>
            <option value="V">V</option>
          </Select>
        </FormGroup>
        <PhoneField
          className={clsx(styles.fullWidthField, alertPhoneOrEmail && styles.warn)}
          warn={!!accountRequirements?.profile?.individual?.phone?.number}
          isLocked={!!accountLocks?.profile?.individual?.phone?.number}
          required={!account.profile?.individual?.email}
          dispatchType="individualPhone"
          value={account.profile?.individual?.phone?.number || ""}
        />
        <AccountInput
          className={clsx(styles.fullWidthField, alertPhoneOrEmail && styles.warn)}
          label="Email"
          name="email"
          type="email"
          value={account.profile?.individual?.email || ""}
          disabled={!!accountLocks?.profile?.individual?.email}
          isLocked={!!accountLocks?.profile?.individual?.email}
          showDefaultWarningMessage={!!accountRequirements?.profile?.individual?.email}
          required={!account.profile?.individual?.phone?.number}
          optional
        />
        {alertPhoneOrEmail && (
          <Alert className={styles.fullWidthField} type="warning">
            An email or a phone number is required.
          </Alert>
        )}
      </div>
    </Step>
  );
};
