import createClient, { Middleware } from "openapi-fetch";
import type { paths } from "@moovfinancial/common/types/__generated-types__/api";
import type { paths as opPaths } from "@moovfinancial/common/types/__generated-types__/operations-api";

export type { Middleware };

const DEFAULT_API_BASE_PATH = "/api";

/**
 * Creates an OpenAPI client with the option of customizing the baseUrl and middlewares
 */
export const create = (
  { baseUrl, middlewares }: { baseUrl: string; middlewares: Middleware[] } = {
    baseUrl: DEFAULT_API_BASE_PATH,
    middlewares: []
  }
) => {
  const openApi = createClient<paths & opPaths>({ baseUrl });

  const removeUndefinedMiddleware: Middleware = {
    async onRequest({ request }: { request: Request }) {
      // If not on POST, PUT, or PATCH, do nothing
      if (!["POST", "PUT", "PATCH"].includes(request.method)) {
        return request;
      }

      const bodyParams = request.body ? ((await request.json()) as Record<string, unknown>) : null;
      if (bodyParams) {
        Object.keys(bodyParams).forEach((key) =>
          bodyParams[key] === undefined ? delete bodyParams[key] : {}
        );
      }
      const newRequest = new Request(request, {
        body: JSON.stringify(bodyParams)
      });
      return newRequest;
    }
  };

  openApi.use(removeUndefinedMiddleware);
  middlewares.forEach((middleware) => openApi.use(middleware));
  return openApi;
};

export default { create };
