import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { openApi } from "@moovfinancial/common/api/OpenApiClient";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { getAccountPatch } from "pages/onboarding/helpers/accounts";
import type { OnboardingErrors, UnparsedOnboardingErrors } from "pages/onboarding/helpers/errors";

// Accounts
export type PatchAccount = components["schemas"]["PatchAccountRequest"];
type AccountResponse = components["schemas"]["Account"];
export type Account = AccountResponse & PatchAccount;

// Bank Accounts
export type BankAccount = components["schemas"]["BankAccountResponse"];
export type BankAccountCreateRequest = Exclude<
  components["schemas"]["BankAccountIntegration"],
  null
>;

// Representatives
export type CreateRepresentative = Omit<
  components["schemas"]["CreateRepresentative"],
  "responsibilities"
> & {
  // TODO: openApiSpecIsWrong - isOwner is defined as a required boolean, but when isController is false, isOwner must be undefined
  // otherwise the API will throw an error
  responsibilities?:
    | (Omit<components["schemas"]["Responsibilities"], "isOwner"> & {
        isOwner?: boolean;
      })
    | null;
};
export type PatchRepresentative = Omit<
  components["schemas"]["PatchRepresentativeRequest"],
  "responsibilities"
> & {
  // TODO: openApiSpecIsWrong - isOwner is defined as a required boolean, but when isController is false, isOwner must be undefined
  // otherwise the API will throw an error
  responsibilities?:
    | (Omit<components["schemas"]["Responsibilities"], "isOwner"> & {
        isOwner?: boolean;
      })
    | null;
};
type RepresentativeResponse = components["schemas"]["Representative"];
export type Representative = Omit<CreateRepresentative, "responsibilities"> &
  Omit<RepresentativeResponse, "responsibilities"> & {
    // TODO: openApiSpecIsWrong - isOwner is defined as a required boolean, but when isController is false, isOwner must be undefined
    // otherwise the API will throw an error
    responsibilities?:
      | (Omit<components["schemas"]["Responsibilities"], "isOwner"> & {
          isOwner?: boolean;
        })
      | null;
  };

// Underwriting
export type Underwriting = components["schemas"]["Underwriting"];

// Files
export type FileResponse = components["schemas"]["File"];
export type FileUpload = components["schemas"]["FileUploadRequest"];

interface OnboardingContextProps {
  children: React.ReactNode;
}

interface Onboarding {
  account: Partial<Account>;
  bankAccounts: BankAccount[];
  createBankAccount: (
    bankAccount: BankAccountCreateRequest
  ) => ReturnType<typeof createBankAccountFn>;
  createMicroDeposits: (bankAccountID: string) => ReturnType<typeof createMicroDepositsFn>;
  createRepresentative: (
    representative: CreateRepresentative
  ) => ReturnType<typeof createRepresentativeFn>;
  files: FileResponse[];
  isLoading: boolean;
  patchAccount: (account: PatchAccount) => ReturnType<typeof patchAccountFn>;
  patchRepresentative: (
    representative: Partial<Representative>
  ) => ReturnType<typeof patchRepresentativeFn>;
  patchUnderwriting: (
    underwriting: Partial<Underwriting>
  ) => ReturnType<typeof patchUnderwritingFn>;
  refreshAccount: () => void;
  refreshBankAccounts: () => void;
  refreshFiles: () => void;
  refreshUnderwriting: () => void;
  removeRepresentative: (representativeID: string) => ReturnType<typeof removeRepresentativeFn>;
  representatives: Representative[];
  underwriting: Partial<Underwriting>;
  uploadFiles: (files: File | File[]) => ReturnType<typeof uploadFilesFn>;
  fetchTosToken: () => ReturnType<typeof fetchTosTokenFn>;
}

export const OnboardingContext = createContext<Onboarding>({
  account: {},
  bankAccounts: [],
  createBankAccount: (bankAccount) => createBankAccountFn(bankAccount, "", ""),
  createMicroDeposits: (bankAccountID) => createMicroDepositsFn(bankAccountID, "", ""),
  createRepresentative: (representative) => createRepresentativeFn(representative, "", ""),
  files: [],
  isLoading: false,
  patchAccount: (account) => patchAccountFn(account, "", ""),
  patchRepresentative: (representative) =>
    patchRepresentativeFn(representative, representative.representativeID ?? "", "", ""),
  patchUnderwriting: (underwriting) => patchUnderwritingFn(underwriting, "", ""),
  refreshAccount: () => {},
  refreshBankAccounts: () => {},
  refreshFiles: () => {},
  refreshUnderwriting: () => {},
  removeRepresentative: () => removeRepresentativeFn("", "", ""),
  representatives: [],
  underwriting: {},
  uploadFiles: (files) => uploadFilesFn(files, "", ""),
  fetchTosToken: () => fetchTosTokenFn("")
});

function fetchAccountFn(accountID: string | undefined, facilitatorID: string) {
  return openApi.GET("/accounts/{accountID}", {
    params: {
      path: {
        accountID: accountID ?? facilitatorID
      }
    },
    headers: {
      "X-Account-ID": facilitatorID
    }
  });
}

function fetchBankAccountsFn(facilitatorID: string) {
  if (!facilitatorID) return;
  return openApi.GET("/accounts/{accountID}/bank-accounts", {
    params: {
      path: {
        accountID: facilitatorID
      }
    },
    headers: {
      "X-Account-ID": facilitatorID
    }
  });
}

function fetchRepresentativesFn(facilitatorID: string) {
  if (!facilitatorID) return;
  return openApi.GET("/accounts/{accountID}/representatives", {
    params: {
      path: {
        accountID: facilitatorID
      }
    },
    headers: {
      "X-Account-ID": facilitatorID
    }
  });
}

function fetchUnderwritingFn(accountID: string | undefined, facilitatorID: string) {
  return openApi.GET("/accounts/{accountID}/underwriting", {
    params: {
      path: {
        accountID: accountID ?? facilitatorID
      }
    },
    headers: {
      "X-Account-ID": facilitatorID
    }
  });
}

function fetchFilesFn(accountID: string | undefined, facilitatorID: string) {
  return openApi.GET("/accounts/{accountID}/files", {
    params: {
      path: {
        accountID: accountID ?? facilitatorID
      }
    },
    headers: {
      "X-Account-ID": facilitatorID
    }
  });
}

function fetchTosTokenFn(facilitatorID: string) {
  return openApi.GET("/tos-token", {
    headers: {
      "X-Account-ID": facilitatorID
    }
  });
}

function createBankAccountFn(
  bankAccount: BankAccountCreateRequest,
  accountID: string | undefined,
  facilitatorID: string
) {
  return openApi.POST("/accounts/{accountID}/bank-accounts", {
    body: { account: bankAccount },
    headers: {
      "X-Account-ID": facilitatorID
    },
    params: {
      path: { accountID: accountID ?? facilitatorID }
    }
  });
}

function createMicroDepositsFn(
  bankAccountID: string,
  accountID: string | undefined,
  facilitatorID: string
) {
  return openApi.POST("/accounts/{accountID}/bank-accounts/{bankAccountID}/verify", {
    headers: {
      "X-Account-ID": accountID,
      "X-Wait-For": "rail-response"
    },
    params: {
      path: {
        accountID: accountID ?? facilitatorID,
        bankAccountID
      }
    }
  });
}

function createRepresentativeFn(
  representative: CreateRepresentative,
  accountID: string | undefined,
  facilitatorID: string
) {
  return openApi.POST("/accounts/{accountID}/representatives", {
    params: {
      path: {
        accountID: accountID ?? facilitatorID
      }
    },
    headers: {
      "X-Account-ID": facilitatorID
    },
    // TODO: openApiSpecIsWrong - Remove when OpenAPI spec is fixed and we don't neet to have all this type mess
    body: representative as components["schemas"]["CreateRepresentative"]
  });
}

function patchAccountFn(
  account: PatchAccount,
  accountID: string | undefined,
  facilitatorID: string
) {
  const patchData = getAccountPatch(account);
  return openApi.PATCH("/accounts/{accountID}", {
    params: {
      path: {
        accountID: accountID ?? facilitatorID
      }
    },
    body: patchData,
    headers: {
      "X-Account-ID": facilitatorID
    }
    // @TODO: openApiSpecIsWrong - errors are not typed correctly in the openapi spec. This is just a bandaid, and should be removed ASAP the error types have been fixed
  }) as Promise<{
    data?: Account;
    error: Partial<OnboardingErrors<Account>> | UnparsedOnboardingErrors<Account>;
    response: Response;
  }>;
}

function patchRepresentativeFn(
  representative: PatchRepresentative,
  representativeID: string,
  accountID: string | undefined,
  facilitatorID: string
) {
  return openApi.PATCH("/accounts/{accountID}/representatives/{representativeID}", {
    params: {
      path: {
        accountID: accountID ?? facilitatorID,
        representativeID: representativeID
      }
    },
    headers: {
      "X-Account-ID": facilitatorID
    },
    // TODO: openApiSpecIsWrong - Remove when OpenAPI spec is fixed and we don't neet to have all this type mess
    body: representative as components["schemas"]["PatchRepresentativeRequest"]
  });
}

function patchUnderwritingFn(
  underwriting: Partial<Underwriting>,
  accountID: string | undefined,
  facilitatorID: string
) {
  return openApi.PUT("/accounts/{accountID}/underwriting", {
    body: underwriting,
    headers: {
      "X-Account-ID": facilitatorID
    },
    params: {
      path: { accountID: accountID ?? facilitatorID }
    }
    // @TODO: openApiSpecIsWrong - errors are not typed correctly in the openapi spec. This is just a bandaid, should be removed ASAP when the errors are correctly typed
  }) as Promise<{
    data?: Underwriting;
    error: Partial<OnboardingErrors<Underwriting>> | UnparsedOnboardingErrors<Underwriting>;
    response: Response;
  }>;
}

function removeRepresentativeFn(
  representativeID: string,
  accountID: string | undefined,
  facilitatorID: string
) {
  return openApi.DELETE("/accounts/{accountID}/representatives/{representativeID}", {
    params: {
      path: {
        accountID: accountID ?? facilitatorID,
        representativeID
      }
    },
    headers: {
      "X-Account-ID": facilitatorID
    }
    // @TODO: openApiSpecIsWrong - errors are not typed correctly in the openapi spec. This is just a bandaid, and should be removed ASAP the error types have been fixed
  }) as Promise<{
    data?: Representative;
    error: Partial<OnboardingErrors<Representative>> | UnparsedOnboardingErrors<Representative>;
    response: Response;
  }>;
}

function uploadFileFn(file: File, accountID: string | undefined, facilitatorID: string) {
  return openApi.POST("/accounts/{accountID}/files", {
    body: {
      /* @ts-expect-error TODO: API type is expecting to be a string instead of a File */
      file,
      filePurpose: "merchant_underwriting"
    },
    bodySerializer(body) {
      const fileFormData = new FormData();
      fileFormData.append("file", body.file);
      fileFormData.append("filePurpose", body.filePurpose);
      return fileFormData;
    },
    headers: {
      "X-Account-ID": facilitatorID
    },
    params: {
      path: { accountID: accountID ?? facilitatorID }
    }
    // @TODO: openApiSpecIsWrong - errors are not typed correctly in the openapi spec. This is just a bandaid, and should be removed ASAP the error types have been fixed
  }) as Promise<{
    data?: FileResponse;
    error: Partial<OnboardingErrors<FileResponse>> | UnparsedOnboardingErrors<FileResponse>;
    response: Response;
  }>;
}

function uploadFilesFn(files: File | File[], accountID: string | undefined, facilitatorID: string) {
  const f = Array.isArray(files) ? files : [files];
  return Promise.allSettled(f.map((file) => uploadFileFn(file, accountID, facilitatorID)));
}

export function OnboardingContextProvider({ children }: OnboardingContextProps) {
  const { facilitatorID } = useContext(FacilitatorContext);
  const [account, setAccount] = useState<Account>({});
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [files, setFiles] = useState<FileResponse[]>([]);
  const [representatives, setRepresentatives] = useState<Representative[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [underwriting, setUnderwriting] = useState<Underwriting>({});

  const fetchAccount = useCallback(async () => {
    const { data, error } = await fetchAccountFn(account?.accountID, facilitatorID);

    if (!error && data) {
      setAccount(data);
    }
  }, [account?.accountID, facilitatorID]);

  const fetchBankAccounts = useCallback(async () => {
    // TODO: openApiSpecIsWrong - The response to this can undefined?
    const { data, error } = (await fetchBankAccountsFn(facilitatorID)) ?? {};

    if (!error && data) {
      setBankAccounts(data);
    }
  }, [facilitatorID]);

  const fetchFiles = useCallback(async () => {
    const { data, error } = await fetchFilesFn(account?.accountID, facilitatorID);

    if (!error && data) {
      setFiles(data);
    }
  }, [account?.accountID, facilitatorID]);

  const fetchRepresentatives = useCallback(async () => {
    // TODO: openApiSpecIsWrong - The response to this can undefined?
    const { data, error } = (await fetchRepresentativesFn(facilitatorID)) ?? {};

    if (!error && data) {
      setRepresentatives(data);
    }
  }, [facilitatorID]);

  const fetchUnderwriting = useCallback(async () => {
    const { data, error } = await fetchUnderwritingFn(account?.accountID, facilitatorID);

    if (!error && data) {
      setUnderwriting(data);
    }
  }, [account?.accountID, facilitatorID]);

  const fetchTosToken = useCallback(async () => {
    const response = await fetchTosTokenFn(facilitatorID);

    return response;
  }, [facilitatorID]);

  const createBankAccount = useCallback(
    async (bankAccount: BankAccountCreateRequest) => {
      const response = await createBankAccountFn(bankAccount, account.accountID, facilitatorID);

      if (response.data && !response.error) {
        await fetchBankAccounts();
      }

      return response;
    },
    [account.accountID, facilitatorID, fetchBankAccounts]
  );

  const createMicroDeposits = useCallback(
    async (bankAccountID: string) => {
      const response = await createMicroDepositsFn(bankAccountID, account.accountID, facilitatorID);

      if (response.data && !response.error) {
        await fetchBankAccounts();
      }

      return response;
    },
    [account.accountID, facilitatorID, fetchBankAccounts]
  );

  const createRepresentative = useCallback(
    async (representative: CreateRepresentative) => {
      const response = await createRepresentativeFn(
        representative,
        account.accountID,
        facilitatorID
      );

      if (response.data && !response.error) {
        await fetchRepresentatives();
      }

      return response;
    },
    [account.accountID, facilitatorID, fetchRepresentatives]
  );

  const patchAccount = useCallback(
    async (acct: PatchAccount) => {
      const response = await patchAccountFn(acct, account.accountID, facilitatorID);

      if (response.data && !response.error) {
        await fetchAccount();
      }

      return response;
    },
    [account.accountID, facilitatorID, fetchAccount]
  );

  const patchRepresentative = useCallback(
    async (representative: Partial<Representative>) => {
      const response = await patchRepresentativeFn(
        representative,
        representative.representativeID ?? "",
        account?.accountID,
        facilitatorID
      );

      if (response.data && !response.error) {
        await fetchRepresentatives();
      }

      return response;
    },
    [account?.accountID, facilitatorID, fetchRepresentatives]
  );

  const patchUnderwriting = useCallback(
    async (underwriting: Partial<Underwriting>) => {
      const response = await patchUnderwritingFn(underwriting, account?.accountID, facilitatorID);

      if (response.data && !response.error) {
        await fetchUnderwriting();
      }

      return response;
    },
    [account?.accountID, facilitatorID, fetchUnderwriting]
  );

  const removeRepresentative = useCallback(
    async (representativeID: string) => {
      const response = await removeRepresentativeFn(
        representativeID,
        account?.accountID,
        facilitatorID
      );

      if (response.data && !response.error) {
        await fetchRepresentatives();
      }

      return response;
    },
    [account?.accountID, facilitatorID, fetchRepresentatives]
  );

  const uploadFiles = useCallback(
    async (files: File | File[]) => {
      const response = await uploadFilesFn(files, account?.accountID, facilitatorID);

      if (response.some((r) => r.status === "fulfilled")) {
        await fetchFiles();
      }

      return response;
    },
    [account?.accountID, facilitatorID, fetchFiles]
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await fetchAccount();

        // Don't run these parallel to fetchAccount because we want the account fetched first
        await Promise.allSettled([
          fetchBankAccounts(),
          fetchFiles(),
          fetchRepresentatives(),
          fetchUnderwriting()
        ]);
      } finally {
        setIsLoading(false);
      }
    };

    if (!account?.accountID) {
      void fetchData();
    }
  }, [
    account?.accountID,
    fetchAccount,
    fetchBankAccounts,
    fetchFiles,
    fetchRepresentatives,
    fetchUnderwriting,
    underwriting
  ]);

  const value = useMemo<Onboarding>(
    () => ({
      account,
      bankAccounts,
      createBankAccount,
      createMicroDeposits,
      createRepresentative,
      fetchTosToken,
      files,
      isLoading,
      patchAccount,
      patchRepresentative,
      patchUnderwriting,
      refreshAccount: fetchAccount,
      refreshBankAccounts: fetchBankAccounts,
      refreshFiles: fetchFiles,
      refreshUnderwriting: fetchUnderwriting,
      removeRepresentative,
      representatives,
      underwriting,
      uploadFiles
    }),
    [
      account,
      bankAccounts,
      createBankAccount,
      createMicroDeposits,
      createRepresentative,
      fetchAccount,
      fetchBankAccounts,
      fetchFiles,
      fetchTosToken,
      fetchUnderwriting,
      files,
      isLoading,
      patchAccount,
      patchRepresentative,
      patchUnderwriting,
      removeRepresentative,
      representatives,
      underwriting,
      uploadFiles
    ]
  );

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
}
