import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  BirthDate,
  IncompleteBirthDate,
  birthDateToDate,
  birthDateToFormattedInput,
  dateToBirthDate,
  formattedDateStringToBirthDate,
  isBirthDate
} from "@moovfinancial/common/utils/format/formatDate";
import {
  DateMaskingInput,
  type DateMaskingInputProps
} from "../../../../Form/Input/MaskingInputs/DateMaskingInput";

export type { BirthDate, IncompleteBirthDate } from "@moovfinancial/common/utils/format/formatDate";

export type BirthDateMaskingInputProps = Omit<DateMaskingInputProps, "onValueChange" | "value"> & {
  /**
   * function called on value change. The function should update the value in the parent component's state.
   */
  onValueChange?: (value: BirthDate | undefined, formattedValue: string, isValid: boolean) => void;
  /**
   * The BirthDate or IncompleteBirthDate value of the input
   */
  value: BirthDate | undefined;
  /**
   * Should the input validate the date?
   *
   * If true, the input will check that the date is between [1900-01-01, today] and show an error if it is not
   *
   * @default true
   */
  shouldValidate?: boolean;
};

const INVALID_DATE_MESSAGE = "Please enter a valid date";

// Check if the passed string contains exactly 8 digits, discarding any non-digit characters
const isFullFormattedDate = (value: string) => value.replace(/\D/g, "").length === 8;

const validate = (date?: Date) => !!date && date >= new Date(1900, 0, 1) && date <= new Date();

export function BirthDateMaskingInput({
  onValueChange,
  value,
  shouldValidate = true,
  ...rest
}: BirthDateMaskingInputProps) {
  const previousValue = useRef(value);
  const [birthDate, setBirthDate] = useState<BirthDate | IncompleteBirthDate | undefined>(value);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    // Update the local state if the value changes (like for decrypted values)
    if (
      previousValue.current?.day !== value?.day ||
      previousValue.current?.month !== value?.month ||
      previousValue.current?.year !== value?.year
    ) {
      previousValue.current = value;
      setBirthDate(value);
    }
  }, [value]);

  const valueDateOrString = useMemo(() => {
    if (!birthDate) {
      return "";
    }
    if (isBirthDate(birthDate)) {
      return birthDateToDate(birthDate);
    }
    return birthDateToFormattedInput(birthDate);
  }, [birthDate]);

  const handleOnValueChange = useCallback(
    (value: Date | undefined, formattedValue: string) => {
      let isValidLocal = true;
      let updatedBirthDate: BirthDate | undefined;
      // We only get a `value` Date when a) the user has entered a full date AND b) said date is valid
      if (!value) {
        // We have a partial date OR an invalid date. Update the local state, but don't call onValueChange
        setBirthDate(formattedDateStringToBirthDate(formattedValue));
      } else {
        updatedBirthDate = dateToBirthDate(value);
        setBirthDate(updatedBirthDate);
      }

      // If we have to validate, and the formattedValue corresponds to a complete (read: not necessarily valid) date, we validate the date
      if (shouldValidate && isFullFormattedDate(formattedValue)) {
        isValidLocal = validate(value);
        setIsValid(isValidLocal);
      }

      // We only call onValueChange if we have an updatedBirthDate (which means the date is both complete AND valid)
      if (updatedBirthDate) {
        onValueChange?.(updatedBirthDate, formattedValue, isValidLocal);
      }
    },
    [onValueChange, shouldValidate, setIsValid]
  );

  return (
    <DateMaskingInput
      {...rest}
      onValueChange={handleOnValueChange}
      value={valueDateOrString}
      error={!isValid && INVALID_DATE_MESSAGE}
    />
  );
}
